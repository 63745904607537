@import '_variable.scss';

@mixin init {
    color: $defaultColor;
    border: none;
    font-family: $fontBasic;
    line-height: 1em;
    margin: 0;
    outline: none;
    padding: 0;
    text-decoration: none;
}

@mixin browserCss($prop, $value) {
    -webkit-#{$prop}: $value;
    -moz-#{$prop}: $value;
    -ms-#{$prop}: $value;
    -o-#{$prop}: $value;
    #{$prop}: $value;
}
@mixin clearfix() {
  &:before,
  &:after {
    content: " "; // 1
    display: table; // 2
  }
  &:after {
    clear: both;
  }
}
@mixin nopadd() {
  padding-left:0;
  padding-right:0;
} 
