// Path configuration
$imagePath                : '../images/';
$imageData                : '../images/data/';
$imageDesign              : '../images/design/';

// Font configuration
$fontPath                 : '../fonts/';
$fontBasic                : Roboto, sans-serif;
$fontVerdana              : Roboto, sans-serif;
$roboto                   : Roboto, sans-serif;
$lato                     : Lato, sans-serif;
$abril                    : 'Lato', sans-serif;
$aleg                     : 'Lato', sans-serif;
$work                     : 'Lato', sans-serif;

// color
$green				  : #7ed438;
$defaultColor         : #333333;
$activeColor          : $green;
$black				  : $defaultColor;
$jaune                : #bc9e6d;
$bgColor              : $green;
$silverColor          : #f7f7f7;
$btnColor             : #a2c72a;
$bgColorsimple        : #233354; // couleur facebook
$placecolor           : #2c2c2d; // couleur placeholder
$btnextra             : $green; // couleur bouton vert
$border               : #c5c5c5;
$brown                : #e4e8eb;
$gris 				  : #515253;
$red                  : #802337;
$blue                 : #00548a;
$blu                  : #00548a;

//Queries
$min360                   :"only screen and (min-width: 360px)";
$min361                   :"only screen and (min-width: 361px)";
$min375                   :"only screen and (min-width: 375px)";
$min376                   :"only screen and (min-width: 376px)";
$min414                   :"only screen and (min-width: 414px)";
$min415                   :"only screen and (min-width: 415px)";
$min480                   :"only screen and (min-width: 480px)";
$min481                   :"only screen and (min-width: 481px)";
$min568                   :"only screen and (min-width: 568px)";
$min569                   :"only screen and (min-width: 569px)";
$min640                   :"only screen and (min-width: 640px)";
$min641                   :"only screen and (min-width: 641px)";
$min667                   :"only screen and (min-width: 667px)";
$min668                   :"only screen and (min-width: 668px)";
$min736                   :"only screen and (min-width: 736px)";
$min737                   :"only screen and (min-width: 737px)";
$min769                   :"only screen and (min-width: 769px)";
$min768                   :"only screen and (min-width: 768px)";
$min992                   :"only screen and (min-width: 992px)";
$min1024                  :"only screen and (min-width: 1024px)";
$min1200                  :"only screen and (min-width: 1200px)";
$min1280                  :"only screen and (min-width: 1280px)";
$min1400                  :"only screen and (min-width: 1400px)";
$min1456                  :"only screen and (min-width: 1456px)";

$max360                   :"only screen and (max-width: 360px)";
$max479                   :"only screen and (max-width: 479px)";
$max480                   :"only screen and (max-width: 480px)";
$max639                   :"only screen and (max-width: 639px)";
$max640                   :"only screen and (max-width: 640px)";
$max736                   :"only screen and (max-width: 736px)";
$max767                   :"only screen and (max-width: 767px)";
$max768                   :"only screen and (max-width: 768px)";
$max959                   :"only screen and (max-width: 959px)";
$max991                   :"only screen and (max-width: 991px)";
$max992                   :"only screen and (max-width: 992px)";
$max1023                  :"only screen and (max-width: 1023px)";
$max1024                  :"only screen and (max-width: 1024px)";
$max1199                  :"only screen and (max-width: 1199px)";

$sm-query                 : "only screen and (min-width: 768px) and (max-width: 991px)";
$md-query                 : "only screen and (min-width: 992px) and (max-width: 1199px)";

//== Pagination
//
//##

$pagination-color:                     $bgColorsimple !default;
$pagination-bg:                        #fff !default;
$pagination-border:                    #ddd !default;

$pagination-hover-color:               #FFF !default;
$pagination-hover-bg:                  $bgColorsimple !default;
$pagination-hover-border:              #ddd !default;

$pagination-active-color:              #fff !default;
$pagination-active-bg:                 $bgColorsimple !default;
$pagination-active-border:             $bgColorsimple !default;

$pagination-disabled-color:            $bgColorsimple !default;
$pagination-disabled-bg:               #fff !default;
$pagination-disabled-border:           #ddd !default;