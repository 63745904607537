@charset "UTF-8";
body {
    font-size:14px;
    font-family: 'Lato', sans-serif;
}
.layout-container {
    overflow:visible!important;
}
input[type="text"]:focus, 
input[type="password"]:focus, 
textarea:focus{
    outline: none;
}
* {
-webkit-tap-highlight-color: rgba(0,0,0,0);
         -webkit-tap-highlight-color: transparent; /* For some Androids */
    }
.navbar-toggler {
    align-self: flex-start;
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background: transparent;
    border: 1px solid transparent;
    float: right;
    background: url(../images/hamburger.svg) no-repeat 0 0;
    background-size:100%;
    width: 39px;
    height: 35px;
    margin-top: 33px;
    position: relative;
    margin-right:15px;
    @media (min-width: 992px) {
        display:none;
    }
}
#block-bartik-branding {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
}
.region-header {
    width:100%;
}
#block-bartik-main-menu {
    display: flex;
    width: 100%;
    z-index:99;
    position:relative;
    .content {
        display: flex;
        width: 100%;
    }
    @media (min-width: 992px)  {
        display:flex!important;
    }
    @media (min-width: 992px) {
    ul.menu {
        display:flex;
        list-style:none;
        padding:0;
        margin:0;
        flex-direction: row;
        margin-left: auto;
        > li {
            padding:0 10px;
            position:relative;  
            > a {
                text-transform:uppercase;
                font-size:14px;
                padding:6px 0;
                display:block;
                text-decoration:none;
            }
            > a.active {
                color:#00548a
            }
        }
    }
    li .submenu {
      position:absolute;
      top:25px;
      left:0;
      z-index:1;
      width:180px;
      background: rgba(255, 255, 255, 0.6);
      padding:0;
      display:none;
    }
    li.activeh .submenu {
      display:block;
    }
    li .submenu li {
      display:block;
    }
    li .submenu li a {
      font-size:14px;
      text-transform:none;
      display:block;
      padding:2px 10px;
      text-decoration:none;
    }
    .contact-header {
        color: #333333;
        font-size: 14px;
        font-weight: 700;
        background: url(../images/picto-contact.png) no-repeat 0 3px;
        padding: 6px 0 7px 31px;
        margin-left:45px;
    }
    }
}   
@media (max-width: 992px) {
    #block-bartik-main-menu {
        display:none;
        background:$blue;
    }
    #block-bartik-branding {
        display:block;
    }
    a.site-branding__logo {
        margin-left:-20px;
    }
    .region-header {
        ul.menu {
            list-style:none;
            padding:10px 15px;
            margin:0;
            a {
                color:#FFF;
                display:block;
            }
            > li {
                border-bottom:1px solid #FFF;
                text-align:center;
                padding:15px 0;
                > a {
                    text-transform:uppercase;
                    border-top:none!important;
                }
            }
            > li:last-child {
                border:none;
            }
        }
        ul.submenu {
            list-style:none;
            padding:0;
            margin:0;
        }
        a.contact-header {
            display:none;
        }
    }
}
.container-fluid.empty-content {
    padding-top:0;
}
.container-fluid.empty-content {
    padding-right:0;
    padding-left:0;
    .container {
        width:100%;
        max-width:100%;
        padding-right:0;
        padding-left:0;
    }
}
#wrapper-footer-full {
    p {
        margin:0;
        text-align:center;
        a {
            font-size:14px;
        }
        strong {
            font-weight:normal;
            width:23px;
            text-align:center;
            font-size:14px;
            display:inline-block;
        }
    }
}
.region-header .block ul.clearfix li {
    a:hover {
        color:$blue;
    }
}
@media (min-width: 992px) {
    .navbar-toggleable-md .navbar-nav {
            .nav-link {
                font-size:14px;
                font-weight:700;
                padding:31px 0.6rem 33px 0.6rem;
                color:#777777;
                border-top:none;
                text-transform:none;
                margin-left:5px;
                border-top:3px solid transparent;
            }
    } 
    .navbar-toggleable-md .right-element a {
        display:block;
        font-size:16px;
        color:#FFF;
        font-weight:700;
        background:url(../images/picto-phone.png) no-repeat 0 0;
        width:155px;
        padding:3px 0 4px 39px;
        margin-top:11px;
    }
    .navbar-toggleable-md .right-element a span {
        color:$green;
    }
    .navbar-toggleable-md .navbar-nav .dropdown-menu {
        border:none;
        padding:0;
        margin:0;
        border:none;
        border-radius:0;
        width:200px;
        li:last-child,
        li {
            a {
                color:#2c2c2d;
                font-size:14px;
                font-weight:normal;
            }
            a:hover {
                color:#b1a684;
            }
        }
        li:hover {
            border-color:$green;
            a {
                color:$green;
                background:#FFF;
            }
        }
    }
    .navbar-toggleable-md {
        .navbar-nav .nav-link {
            color:#FFF;
        }

    }
    .wrapper-navbar .contact-header span {
        color:#000;
    }
    body {
        .navbar-toggleable-md {
           background:#FFF;
           .navbar-nav > li > .nav-link {
            color:#333333;
            }
        }
    }
    .navbar-brand {
        margin:0 auto;
    }
    .home {
         .navbar-brand {
            background:none;
            img {
                visibility:visible;
            }
         }
    }
    .wrapper-navbar {
        div.call {
            font-size:24px;
            font-family:$aleg;
            font-weight:700;
            line-height:normal;
            position:absolute;
            top:18px;
            right:0;
            a {
                display:block;
                text-decoration:none;
                font-family:Roboto;
                font-weight:100;
                font-size:24px;
            }
        }
    }
}
@media (max-width: 992px) {
    .navbar {
        position: fixed;
        width: 100%;
        z-index: 9999;
    }
    .container-fluid.empty-content,
    .container-fluid.empty-content.detail-page {
        padding-top:93px;
    }
    .navbar-inverse .navbar-toggler {
       
    }
    .navbar-inverse .navbar-brand {
        
    }
    .navbar-inverse .navbar-nav .nav-link {
        
    }
    .navbar-nav .nav-link {
        padding-right:15px;
        padding-left:15px;
    }
    #navbarNavDropdown {
        
    }
    .wrapper-navbar {
        div.call {
            display:none;
        }
    }
    .navbar-brand img {
        max-width:250px;
    }
}
@media (max-width: 380px) {
     .navbar-brand img {
        max-width:220px;
    }
}
@media (max-width: 1024px) {
    .navbar {
        .right-element {
            display:none;
        }
    }
}
@media (max-width: 1070px) and (min-width: 992px) {
    .navbar-toggleable-md {
        padding-left:15px;
        padding-right:15px;
    }
}
@media (max-width: 992px) {
    .navbar-toggleable-md .navbar-nav {
        .dropdown-menu {
            border:none;
            padding:0 0 0 15px;
            margin:0;
            .nav-link {
                font-size:14px;
                margin:0;
                padding:0;
            }
            .nav-link:hover {
                background:transparent;
            }
        }
    }
    .topbar {
        display:none;
    }
}

    .navbar-toggleable-md .container {
    padding-right:0;
    padding-left:0;
    padding:0;
    max-width:1260px;
    position:relative;
    @media (max-width: 1260px) {
        max-width:100%;
    }
    @media (max-width: 990px) {
        padding: 15px 15px 15px 15px;
    }
}
.inner-row .row {
    max-width: 1260px;
    margin: 0 auto;
}
body.home {
    .BreadCrumbs {
        display:none;
    }
}
.list-blog {
    padding:60px 0 0 0;
    max-width:1200px;
    margin:0 auto;
    h3.description {
        text-align:center;
        font-size:42px;
        color:$gris;
        margin-bottom:55px;
        display:none;
        p {
            margin:0;
        }
    }
    h1.page-blog {
        text-align:center;
        font-size:48px;
        color:$brown;
        padding-bottom:15px;
        width:100%;
        margin-bottom:0;
    }
    .entry-content {
        font-size:14px;
        position:relative;
        padding-top:15px;
        max-height:245px;
        overflow:hidden;
        .btn-4 {
            display:none;
        }
        p {
            margin:0;
            font-size:15px;
            margin-bottom:15px;
        }
        a {
            text-decoration:none;
            cursor:pointer;
        }
    }
    div.clearfix {
        padding-bottom:55px;
    }
    .entry-footer {
        display:none;
    }
    .col-md-12 {
        padding-right:0;
        padding-left:0;
    }
    a.understrap-read-more-link {
        padding:0;
        margin:0;
        display:none;
    }
    a.understrap-read-more-link:hover {
        background:#FFF;
    }
    .inner-blog {
        float: left;
        width: 50%;
        padding-right: 2.5%;
        h2.entry-title {
            a {
                text-decoration:none;
                display:block;
                color:$blue;
                font-size:40px;
            }
        }
    }
    .img-blog {
        float: right;
        width: 50%;
    }
    .entry-meta {
        time.updated {
            display:none;
        }
        time.published.updated {
            display:inline;
        }
        span.byline {
            padding: 1px 10px 0 26px;
            height: 19px;
            display:none;
            font-size:0;
            span a {
                font-size:11px;
                color:#464646;
            }
        }
        span.posted-on {
            font-size:0;
            color:#464646;
            background:url(../images/picto-date.png) no-repeat 0 0;
            padding: 1px 10px 0 26px;
            height: 19px;
            display: inline-block;
            a {
                text-decoration:none;
                color:#464646;
            }
            time {
                font-size:11px;
                color:#464646;
            }
        }
    }
    @media screen and (max-width: 1200px) {
        .entry-content {
            .addtoany_share_save_container {
                position:static;
                left:auto;
                top:auto;
                margin-top:25px;
            }
        }
        div.clearfix {
            padding-left:15px;
            padding-right:15px;
        }
    }
    @media screen and (max-width: 990px) {
        padding-top:25px;
        h3.description {
            font-size:30px;
            margin-bottom:25px;
        }

    }
    @media screen and (max-width: 770px) {
        .inner-blog {
            width:auto;
            float:none;
        }
        .img-blog {
            width:auto;
            float:none;
            padding-bottom:15px;
        }
        div.clearfix {
            padding-bottom:25px;
        }
    }
}
.container-fluid.empty-content.detail-page {
        padding:60px 0 50px 0;
            h1.entry-title {
                font-size: 72px;
                font-family: $aleg;
                font-weight: 700;
                color:$blue;
            }
        .entry-content {
            padding-bottom:25px;
            padding-top:25px;
            p {
                font-size:15px;
            }
        }
        span.posted-on {
            font-size:0;
            color:#464646;
            background:url(../images/picto-date.png) no-repeat 0 2px;
            padding: 1px 10px 0 26px;
            height: 19px;
            display: inline-block;
            a {
                text-decoration:none;
                color:#464646;
            }
            time {
                font-size:11px;
                color:#464646;
            }
        }
        .form-submit {
            .btn {
                margin:0;
                background:$blue;
                padding:15px 25px;
                color:#FFF;
            }
        }
        .entry-meta {
            padding:0 0 10px 0;
            text-align:left;
            span {
                display:inline-block;
                a {
                    display:inline-block;
                    font-size:12px;
                    color:#333333;
                    text-decoration:none;
                }
            }
            .posted-on {
                a {
                    
                }
            }
            .byline {
                padding-left:10px;
                display:none;
                a {
                    
                }
            }
        }
}
.single-post {
    .has-post-thumbnail {
        > img {
            display:none;
        }
    }
    .format-standard {
        .entry-content {
            > img {
                margin-bottom:15px;
            }
        }
    }
    .comment-respond {
        max-width:480px;
        margin-top:15px;
    }
    .separator.single-separator {
        max-width: 745px;
        margin: 45px auto 0 auto;
        border-top: 1px solid #e5e5e5;
        clear: both;
    }
}
.BreadCrumbs {
    max-width:1210px;
    margin:0 auto;
    color:#FFF;
    font-size:16px;
    padding:3px 0 6px 0;
    a {
       color:#FFF; 
    }
    em {
        display:inline-block;
        font-style:normal;
        color:#FFF;
        font-size:12px;
        padding:0 5px;
    }
    span {
        display:inline-block;
        background: url(../images/picto-home.png) no-repeat 0 8px;
        padding: 2px 0 0 25px;
    }
    @media (max-width: 1280px) {
        padding:3px 15px 6px 15px;
    }
}
.page-contact {
    padding-top:40px;
    padding-bottom:80px;
    .gform_wrapper {
        margin:0 auto;
        max-width:560px;
        .top_label .gfield_label {
            display:none;
        }
        .top_label input.large,
        textarea.large {
            width:100%;
            color:#999999;
            font-size:18px;
            font-weight:300;
            background:#FFF;
            border:none;
            padding:13px 16px;
            border:1px solid $brown;
        }
        textarea.large {
            height:175px;
        }
        label.gfield_label+div.ginput_container {
            margin:0!important;
            padding-bottom:11px;
        }
        label.gfield_label+div.ginput_container_textarea {
            padding-bottom:0;
        }
        .gform_footer {
            margin:0;
            padding:25px 0 0 0;
            text-align:center;
            input[type=submit] {
                text-transform:uppercase;
                color:#FFF;
                font-weight:700;
                font-size:18px;
                background:$blue;
                border:none;
                padding: 18px 10px;
                letter-spacing: 1px;
                border-radius:38px;
                width:180px;
                text-align:center;
            }
        }
        li.gfield.gfield_error.gfield_contains_required div.gfield_description {
            display:none;
        }
        li.gfield.gfield_error.gfield_contains_required .ginput_container_text,
        li.gfield.gfield_error.gfield_contains_required .ginput_container_email {
            padding-bottom:0;
        }
        li.gfield.gfield_error.gfield_contains_required {
            margin-top: 0;
            margin-bottom: 0!important;
        }
        li.gfield.gfield_error.gfield_contains_required div.ginput_container, 
        li.gfield_html {
            max-width: 100%;
        }
        .top_label .gfield_error input.large, 
        .top_label .gfield_error select.large, 
        .top_label .gfield_error textarea.textarea {
            width: 100%;
        }
        .top_label .gfield_error {
            width:100%;
        }
        .top_label .gfield_error.middle-width {
            width:48%;
        }
        li.gfield.gfield_error, 
        li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
            border:none;
            background:#FFF;
        }
        li.gfield_error input[type=email], 
        li.gfield_error input[type=number], 
        li.gfield_error input[type=password], 
        li.gfield_error input[type=tel], 
        li.gfield_error input[type=text], 
        li.gfield_error input[type=url], 
        li.gfield_error textarea {
            border:1px solid #790000;
        }
        li#field_1_1.gfield.gfield_error.gfield_contains_required div.ginput_container {
            padding-bottom:29px;
        }
    }
    .gform_confirmation_message {
        font-size:16px;
        font-weight:700;
        padding-top:20px;
    }
    div.titre {
        max-width:890px;
        margin:0 auto;
        h2 {
            color:$blue;
            font-size:36px;
            font-weight:100;
            /*background:url(../images/contact-infos.jpg) no-repeat center left;*/
            padding:25px 0 60px 0;
            text-align:center;
            span {
                font-size:80px;
                font-weight:700;
                font-family:$aleg;
                display:block;
            }
        }
        @media screen and (max-width: 960px) {
            padding-left:15px;
            padding-right:15px;
            h2 {
                background-size: auto 100%;
                padding:75px 0 50px 35%;
                font-size:30px;
                span {
                    font-size:65px;
                }   
            }
        }
        @media screen and (max-width: 880px) {
            h2 {
                font-size:24px;
                padding:59px 0 43px 28%;
                span {
                    font-size:44px;
                }
            }
        }
        @media screen and (max-width: 830px) {
            h2 {
                padding:59px 0 43px 35%;
            }
        }
        @media screen and (max-width: 680px) {
            h2 {
                padding:0 0 20px 0;
                background:none;
                text-align:center;
            }
        }
        @media screen and (max-width: 480px) {
            h2 {
                font-size:24px;
                span {
                    font-size:30px;
                }
            }
        }
    }
}
.banniere-home {
    background:url(../images/banniere-top.jpg) no-repeat top center;
    > .row {
        min-height:678px;
        color:#FFF;
        position:relative;
    }
    .central {
        display:table;
        height:100%;
        vertical-align:middle;
        max-width:1260px;
        margin:0 auto;
        padding-top:20px;
        position:relative;
        z-index:1;
    }
    .central_inner {
        display:table-cell;
        vertical-align:top!important;
        text-align:left;
    }
    .col-lg-12 {
        padding-left:0;
    }
    h3 {
        margin:120px 0 19px 0;
        line-height:normal;
        font-size:54px;
        font-weight:900;
        font-family: 'Lato', sans-serif;
        span {
            display:block;
            width:110px;
            height:5px;
            background:#FFF;
            margin-top:5px;
        }
    }
    p.infos {
        font-size:22px;
        font-family:$aleg;
        font-weight:700;
        margin:0;
        margin-bottom:20px;
    }
    p.txt {
        font-size:18px;
        margin-bottom:5px;
        strong {
            text-transform:uppercase;
        }
    }
    p.txt {
        visibility:hidden;
        opacity:0;
    }
    p {

    }
    p.cta {
        margin:0;
        position:absolute;
        bottom:58px;
        left:0;
        width:100%;
        text-align:center;
        a.link-offre {
            text-decoration:none;
            display:inline-block;
            border:1px solid #000;
            color:#FFF;
            background:#000;
            padding:15px 25px;
            font-family:$aleg;
            font-weight:100;
            text-transform:uppercase;
            font-size:16px;
            margin-right:35px;
        }
        a.link-offre:after {
            display:none;
        }
        a.link-contact {
            background-color: rgba(0, 0, 0, 0.4);
            background: rgba(0, 0, 0, 0.4);
            color:#FFF;
            border:1px solid #FFF;
        }
    }
    @media (max-width: 1280px) {
        background-size:cover;
        .col-lg-12 {
            padding-left:15px;
        }
    }
    @media (max-width: 1124px) {
        .row {
            background-color: rgba(0, 0, 0, 0.4);
            background: rgba(0, 0, 0, 0.4);
        }
    }
    @media (max-width: 992px) {
        h3 {
            margin:90px 0 19px 0;
            font-size:48px;
        }
        p.infos {
            br {
                display:none;
            }
        }
        p.cta {
            position:static;
        }
        > .row {
            min-height: 565px;
            .col-lg-6 {
                margin-left:0;
            }
        }
    }
    @media (max-width: 680px) {
        > .row {
            min-height:100px;
        }
        a.link-go {
            padding:10px 15px;
            font-size:16px;
        }
        h3 {
            margin:40px 0 19px 0;
            font-size:40px;
        }
        .central {
            padding-bottom:40px;
        }
        p.infos {
            font-size:20px;
        }
    }   
    @media (max-width: 480px) {
       h3 {
            margin:20px 0 19px 0;
            font-size:34px;
        }
        p.txt {
            font-size:16px;
        }
    }
    @media (max-width: 380px) {
        
    }
}
.row {
    .central {
        display: table;
        height: 100%;
        vertical-align: middle;
        width:100%;
    }
    .central_inner {
        display: table-cell;
        vertical-align: middle;
    }
}
.error404,
body.author  {
    .container-fluid.empty-content {
        padding-left:15px;
        padding-right:15px;
        p.green-4 {
            text-align:center;
            font-size:73px;
        }
        h1 {
            font-size:48px;
            text-align:center;
        }
    }
}
body.author {
    .container-fluid.empty-content {
        border-top:1px solid #ececee;
        .container {
            max-width:1210px;
            padding-top:45px;
        }
    }
}
a.link-go,
strong.link-go {
    text-decoration:none;
    display:inline-block;
    border:1px solid $blue;
    color:$blue;
    background:#FFF;
    padding:10px 55px;
    font-weight:700;
    text-transform:uppercase;
    font-size:18px;
    border-radius:28px;
}
a.link-go:hover {
    background:$blue;
    color:#FFF;
}
.bloc-novatrice {
    margin-bottom:65px;
    @media (max-width: 768px) {
        margin-bottom:0;
    }
    .owl-theme .owl-nav {
        display:none;
    }
    .owl-carousel .owl-stage-outer {
        margin-bottom:25px;
    }
    .owl-theme .owl-dots .owl-dot.active span, 
    .owl-theme .owl-dots .owl-dot:hover span {
        background:$blue;
    }
    .owl-theme .owl-dots .owl-dot span {
        width:13px;
        height:13px;
    }
    .txt {
        h2 {
            font-size:30px;
            font-weight:300;
            color:$blue;
            margin-bottom:0;
            margin-top:0;
            span {
                display:block;
                font-weight:700;
                font-size:78px;
                margin-top:-8px;
            }
        }
        h3 {
            margin:0;
            font-size:54px;
            color:$blue;
            font-family: Lato;
            font-weight:900;
            margin-bottom:30px;
            line-height:100%;
        }
        p.txt {
            font-size:18px;
            margin-bottom:35px;
            line-height: 160%;
        }
        p.cta {
            a.link-go {
                padding:13px 48px;
            }
            a.ctn {
                background:$blue;
                color:#FFF;
                margin-left:15px;
            }
        }
        @media (max-width: 1100px) {
            p.cta {
                a.link-go {
                    padding:13px 28px;
                }
                a.ctn {
                    margin-left:10px;
                }
            }
            h2 span {
                font-size:65px;
            }
            h2 {
                font-size:24px;
            }
            h3 {
                font-size:34px;
            }
        }
        @media (max-width: 992px) {
            p.cta {
                text-align:center;
                padding-bottom:0;
                margin:0;
            }
            h2 {
                span {
                    display:inline;
                }
            }
            p.txt {
                br {
                    display:none;
                }
            }
        }
        @media (max-width: 768px) {
            h2 {
                margin-bottom:0;
                span {
                    display:block;
                }
            }
        }
        @media (max-width: 460px) {
            p.cta {
                a.link-go {
                    clear:both;
                    display:block;
                    float:left;
                }
                a.ctn {
                    margin-top:10px;
                    margin-left:0;
                }
            }
            p.txt {
                font-size:15px;
            }
            h2 {
                font-size:24px;
                span {
                    font-size:48px;
                    margin-top:0;
                }
            }
        }
         @media (max-width: 380px) {
            p.cta {
                padding-bottom:25px;
                a.link-go {
                    clear:both;
                    display:block;
                    float:left;
                    font-size:15px;
                }
                a.ctn {
                    margin-top:10px;
                    margin-left:0;
                }
            }
            p.txt {
                font-size:14px;
            }
            h2 {
                font-size:20px;
                span {
                    font-size:40px;
                    margin-top:0;
                }
            }
        }
    }
    @media (max-width: 992px) {
        .img {
            text-align:center;
        }
    }
}
.bloc-futur {
    padding-bottom:45px;
    @media (max-width: 992px) {
        padding-bottom:0;
    }
    div.titre {
        width:100%;
        text-align:center;
        padding:0 0 65px 0;
        h2 {
            font-size: 34px;
            font-weight: 100;
            color: #333333;
            margin-bottom:30px;
            span {
                display:block;
                text-align:center;
                font-weight:700;
                font-size:78px;
            }
        }
        p {
            margin:0;
            font-size:18px;
            line-height: 145%;
        }
        @media (max-width: 980px) {
            h2 {
                font-size:24px;
                span {
                    font-size:66px;
                }
            }
        }
        @media (max-width: 768px) {
            padding:0 15px 0 15px;
            p {
                br {
                    display:none;
                }
                strong {
                    display:block;
                }
            }
        }
        @media (max-width: 440px) {
            h2 {
                font-size:22px;
                span { 
                    font-size:41px;
                }
            }
        }
        @media (max-width: 355px) {
            h2 {
                font-size:19px;
            }
        }
    }
    .box {
        text-align:center;
        position:relative;
        padding-bottom:35px;
        h3 {
            padding:35px 0 0 0;
            color:#333333;
            font-size:34px;
            font-family:$aleg;
            font-weight:700;
            margin:0;
            line-height:100%;
            min-height:115px;
        }
        p.txt {
            font-size:18px;
            margin-bottom:55px;
        }
        p.bt {
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            
            text-align:center;
        }
        @media (max-width: 1160px) {
            h3 {
                font-size:26px;
                min-height:95px;
            }
            p.txt {
                font-size:15px;
            }
        }
        @media (max-width: 980px) {
            a.link-go {
                padding:13px 15px;
                font-size:16px;
            }
        }
        @media (max-width: 768px) {
            padding-bottom:0;
            h3 {
                padding:10px 0 15px 0;
                min-height:25px;
            }
            p.txt {
                margin-bottom:35px;
                font-size:18px;
                max-width:480px;
                margin:0 auto;
                padding-bottom:35px;
            }
            p.bt {
                position:static;
            }
        }   
    }
    a.link-go {
        font-size:18px;
        font-weight:700;
        padding:13px 30px;
        text-align:center;
    }
    p.bt {
        width:100%;
        text-align:center;
    }
}
.bloc-partenaire {
    background:url(../images/banniere-partenaire.jpg) no-repeat top center;
    padding:70px 0 55px 0;
    height:881px;
    h2 {
        width:100%;
        text-align:center;
        color:#FFF;
        font-size:54px;
        font-family:$work;
        font-weight:700;
        margin-bottom:65px;
        margin-top:0;
    }
    .row {
        max-width:975px;
    }
    .box {
        padding-right:0;
        padding-bottom:15px;
    }
    p.bt {
        width:100%;
        text-align:center;
        margin-bottom:0;
        padding-top:30px;
        a.link-go {
            color:#FFF;
            border-color:#FFF;
            background:transparent;
            padding:13px 50px;
        }
    }
    #contact-site-form.contact-form {
        max-width:800px;
        margin:0 auto;
        .form-item {
            margin:0;
            padding-bottom:15px;
            label {
                display:none;
            }
            label.error {
                color:red;
            }
            .form-text,
            #edit-message {
                border:1px solid #FFF;
                width:100%;
                background:transparent;
                color:#FFF;
                font-size:16px;
                padding: 17px 25px;
                border-radius: 24px;
            }
            .form-text.error,
            .form-textarea.errow {
                border:1px solid red;
            }
            .form-item #edit-message.error {
                border:1px solid red!important;
            }
            input::-webkit-input-placeholder,
            textarea::-webkit-input-placeholder { /* Chrome/Opera/Safari */
              color: #FFF;
            }
            input::-moz-placeholder,
            textarea::-moz-placeholder { /* Firefox 19+ */
              color: #FFF;
            }
            input:-ms-input-placeholder, 
            textarea:-ms-input-placeholder{ /* IE 10+ */
              color: #FFF;
            }
            input:-moz-placeholder,
            textarea:-moz-placeholder { /* Firefox 18- */
              color: #FFF;
            }
        }
        .form-type-checkbox {
            label {
                display:inline;
                color:#FFF;
            }
        }
        .form-actions {
            text-align:right;
            padding:0;
            position:relative;
            input.form-submit {
                font-weight: 700;
                text-transform: uppercase;
                font-size: 18px;
                border-radius: 28px;
                color: #FFF;
                border-color: #FFF;
                background: transparent;
                padding: 13px 50px;
                margin-top:11px;
            }
            #google_recaptcha_contact_site_form {
                position:absolute;
                top:0;
                left:0;
            }
        }
    }
    @media (max-width: 992px) {
        height:auto;
        padding:35px 0 35px 0;
        background-size:cover;
        h2 {
            font-size:48px;
            margin-bottom:35px;
        }
        .row {
            padding-left:15px;
            padding-right:15px;
        }
    }
    @media (max-width: 768px) {
        .box {
            text-align:center;
        }
        h2 {
            font-size:40px;
        }
        #contact-site-form.contact-form .form-actions #google_recaptcha_contact_site_form {
            position:static;
            > div {
                margin:0 auto;
            }
        }
        #contact-site-form.contact-form .form-actions {
            text-align:center;
        }
    }
    @media (max-width: 575px) {
        .box {
            width:50%;
            padding-left:0;
        }
        h2 {
            font-size:30px;
            margin-bottom:20px;
        }
    }
     @media (max-width: 480px) {
        .box {
            padding-left:10px;
            padding-right:10px;
        }
     }
    @media (max-width: 380px) {
        h2 {
            font-size:24px;
        }
        p.bt {
            padding-top:20px;
            a.link-go {
                padding:10px 40px;
                font-size:16px;
            }
        }
        #contact-site-form.contact-form .form-actions input.form-submit{
            padding:13px 25px;
        }
    }
}
.page-contact {
    .bloc-partenaire {
        padding:90px 0 55px 0;
        height:auto;
    }
    .banniere-titre {
        margin-top:0;
    }
}
.bloc-project {
    background:url(../images/banniere-project.jpg) no-repeat top center;
    height:851px;
    padding:100px 0 0 0;
    h2 {
            font-size: 24px;
            font-weight: 100;
            color: #FFF;
            margin-bottom:40px;
            background:url(../images/picto-project.gif) no-repeat center left;
            padding:0 0 0 125px;
            span {
                display:block;
                font-weight:900;
                font-size:54px;
                margin-top:-8px;
                line-height:100%;
            }
    }
    a.link-go {
        color: #FFF;
        border-color: #FFF;
        background: transparent;
        padding: 13px 40px;
        margin-left:125px;
    }
    .webform-client-form {
        margin-top:75px;
        background: rgba(255, 255, 255, 0.7);
        padding:45px 6.5%;
    }
    div.cols-f {
        float:left;
        width:33.33%;
        .form-item {
            position:relative;
        }
        label.error {
            position:absolute;
            bottom:-10px;
            left:0;
            color:red;
        }
        .form-item input.error {
            border:1px solide red!important;
        }
    }
    .form-actions {
        clear:both;
        width: 100%;
        text-align:center;
        padding:0;
        margin:0;
        padding-top:30px;
        position:relative;
        input.form-submit {
            text-decoration: none;
            display: inline-block;
            border: 1px solid $blue;
            color: $blue;
            background: #FFF;
            padding: 17px 55px;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 18px;
            border-radius: 28px;
            margin:0;
        }
        input.form-submit:hover {
            background:$blue;
            color:#FFF;
        }
        #google_recaptcha_webform_client_form_13 {
            position:absolute;
            top:0;
            left:0;
        }
        @media (max-width: 992px) {
            padding-top:0;
           #google_recaptcha_webform_client_form_13 {
                position:static;
                margin-bottom:15px;
            }
        }
    }
    .cols-f.step-1 {
        .form-item {
            margin:0;
            padding-bottom:15px;
            input.form-text,
            select {
                width:100%;
                max-width:250px;
                border: 1px solid $blue;
                width: 100%;
                background: transparent;
                color: $blue;
                font-size: 16px;
                padding: 10px 25px;
                border-radius: 18px;
            }
            select {
                height:38px;
                padding:0 25px;
                 -webkit-appearance: none; -moz-appearance: none; appearance: none;
            }
            label {
                font-size: 14px;
                margin-bottom: 6px;
            }
        }
    }
    .cols-f.step-3 {
        padding-right:25px;
        .form-item {
            margin:0;
            padding-bottom:12px;
            input.form-text,
            select {
                width:100%;
                border: 1px solid $blue;
                background: transparent;
                color: $blue;
                font-size: 16px;
                padding: 10px 25px;
                border-radius: 18px;
            }
            label {
                font-size: 14px;
                margin-bottom: 6px;
            }
        }
        #webform-component-telephone {
            float:left;
            width:48%;
        }
        #webform-component-code-postal {
            float:right;
            width:48%;
        }
        #webform-component-votre-nom {
            clear:both;
        }
    }
    .cols-f.step-2 {
        .form-item {
            margin:0;
            padding-bottom:18px;
            input.form-text,
            select {
                width:100%;
                border: 1px solid $blue;
                background: transparent;
                color: $blue;
                font-size: 16px;
                padding: 10px 25px;
                border-radius: 18px;
            }
            label {
                font-size: 14px;
                margin-bottom: 6px;
                clear:both;
            }
            select {
                height:38px;
                padding:0 25px;
                 -webkit-appearance: none; -moz-appearance: none; appearance: none;
                 max-width:265px;
            }
        }
        .form-radios {
            @include clearfix();
            .form-item {
                float:left;
                padding-right:10px;
            }
        }
    }
    @media (max-width: 992px) {
        background-size:cover;
        padding-top:50px;
        height:725px;
        h2 {
            font-size:28px;
            background-size: 53px auto;
            padding-left:75px;
            margin-bottom:25px;
            span {
                font-size:58px;
            }
        }
         a.link-go {
            font-size:16px;
            padding:13px 25px;
            margin-left:75px;
         }
        .webform-client-form {
            padding:25px 2.5%;
            margin-top:35px;
        }
        .cols-f.step-3 {
            padding-right:0;
        }
    }
    @media (max-width: 830px) {
        .cols-f.step-2 {
            padding:0 25px;
        }
        .cols-f {
            .form-item {
                label {
                    font-size:12px!important;
                }
            }
        }
    }
    @media (max-width: 768px) {
        height:auto;
        div.cols-f {
            width:100%;
            float:none;
            .form-item {
                label {
                    font-size:16px!important;
                    display:block;
                }
            }
        }
        .cols-f.step-2 {
            padding:0;
        }
    }
    @media (max-width: 660px) {
        padding-top:30px;
        h2 {
            font-size:22px;
            background-size: 40px auto;
            padding-left: 55px;
            margin-bottom: 15px;
            span {
                font-size:40px;
            }
        }
        a.link-go {
            margin-left:55px;
            padding:12px 15px;
            font-size:14px;
        }
    }
    @media (max-width: 380px) {
        padding-top:20px;
        h2 {
            font-size:18px;
            span {
                font-size:30px;
                margin-top:-5px;
            }
        }
        a.link-go {
            padding: 10px 15px;
            font-size: 12px;
        }
    }
}
.before-footer {
    padding-bottom:20px;
    .row {
        max-width:1070px;
        div.logo {
            float:left;
            width:196px;
            margin-top:-28px;
        }
        .fa {
            display:block;
            float:left;
            font-size:20px;
            width:45px;
            color:$blue;
        }
        span {
            display:block;
            float:left;
            line-height:145%;
        }
        div.adress {
            float:left;
            font-size:14px;
            line-height:100%;
            margin-right:6.5%;
            .fa {
                font-size:22px;
            }
        }
        a.phone {
            display:block;
            text-decoration:none;
            float:left;
            margin:0 6.5% 0 0;
            color:$blue;
            text-decoration:none;
        }
        a.email {
            display:block;
            text-decoration:none;
            float:left;
            margin:0 6.5% 0 0;
            color:$blue;
            text-decoration:none;

        }
        p.web {
            float:left;
        }
        a:hover {
            .fa,
            span {
                color:#000;
            }
        }
    }
    @media (max-width: 1070px) {
        padding-left:15px;
        padding-right:15px;
        .row {
            div.adress  {
                width:30%;
                margin-right:0;
            }
            .fa {
                width:28px;
            }
            div.logo {
                width:12%;
            }
            p.web {
                width:19%;
            }
            a.phone,
            a.email {
                width:30%;
                margin-right:0;
            }
            a.phone {
                
            }
        }
    }
    @media (max-width: 860px) {
        .row {
            div.logo {
                width:100%;
                float:none;
                margin-bottom:10px;
            }
            div.adress {
                span {
                    width: calc( 100% - 39px);
                }
            }
            div.adress,
            a.phone,
            a.email,
            p.web {
                width:36%;
            }
        }
    }
    @media (max-width: 670px) {
        .row {
            div.adress,
            a.phone,
            a.email,
            p.web {
                width:45%;
                margin-bottom:10px;
                span {
                    font-size:12px;
                }
            }
            a.email {
                width:28%;
            }
        }
    }
    @media (max-width: 460px) {
        .row {
            div.adress,
            a.phone,
            a.email,
            p.web {
                width:100%;
                span {
                    font-size:14px;
                }
            }
        }
    }
}
@media (max-width: 860px) {
   
}
.bloc-agence {
    background:url(../images/banniere-agence.jpg) no-repeat top center;
    min-height:771px;
    padding-top:20px;
    color:#FFF;
    h2 {
        span {
            display:none;
        }
    }
    p.titre {
        font-size:18px;
        line-height: 145%;
        margin:0;
        padding-bottom:30px;
        strong {
            font-size:24px;
            font-weight:700;
        }
        span {
            font-weight:700;
        }
    }
    a.link-go {
        padding:16px 40px;
    }
    @media (max-width: 992px) {
        p.titre {
            font-size:16px;
        }
        a.link-go {
            padding:14px 30px;
        }
    }
    @media (max-width: 830px) {
        min-height:100px;
        padding-top:0;
        .row {
            background-color: rgba(0, 0, 0, 0.4);
            background: rgba(0, 0, 0, 0.4);
            padding-top:30px;
            padding-bottom:30px;
            h2 {
                max-width:375px;
            }
        }
    }
}
.bloc-objectif {
    .txt {
        padding-top:75px;
        h2 {
            font-size:36px;
            color:$blue;
            font-weight:100;
            background:url(../images/picto-agence.png) no-repeat center left;
            padding:0 0 0 75px;
            margin-bottom:35px;
            span {
                font-family:$aleg;
                font-size:80px;
                font-weight:700;    
            }
        }
        p {
            font-size:18px;
        }
    }
    @media (max-width: 990px) {
        .txt {
            padding-top:35px;
            h2 {
                font-size:28px;
                padding: 0 0 0 55px;
                margin-bottom: 15px;
                background-size: 35px auto;
                span {
                    font-size:60px;
                }
            }
            p {
                font-size:16px;
            }
        }
    }
    @media (max-width: 768px) {
        .img {
            padding-bottom:15px;
            img {
                max-width:375px;
            }
        }
    }
    @media (max-width: 480px) {
        .txt {
            p {
                br {
                    display:none;
                }
            }
        }
    }
    @media (max-width: 400px) {
        .txt {
            h2 {
                font-size:22px;
                span {
                    font-size:45px;
                }
            }
            p {
                font-size:15px;
            }
        }
    }
    @media (max-width: 400px) {
        .img {
            img {
                max-width:100%;
            }
        }
    }   
}
.bloc-equipe {
    .row {
        max-width: 1366px;
        .col-lg-12 {
            padding-left:0;
            padding-right:0;
        }
    }
    div.img {
        flex: 0 0 28%;
    }
    div.silver {
        background:#f4f4f5;
        flex:0 0 72%;
        padding:45px 0 0 15px;
        h2 {
            font-size:36px;
            color:$blue;
            font-weight:100;
            margin-bottom:45px;
            span {
                font-family:$aleg;
                font-size:80px;
                font-weight:700;
                display:block;
                margin-top:-10px;    
            }
        }
        p.titre {
            font-size:18px;
        }
        a.link-go {
            background:transparent;
            padding:12px 35px;
        }
        ul {
            list-style:none;
            margin:0;
            padding:75px 0 0 0;
            li {
                float:left;
                width:25%;
                text-align:center;
                span {
                    display:block;
                    text-align:center;
                    font-size:48px;
                    font-family:$aleg;
                    font-weight:700;
                    padding-top:15px;
                }
                p {
                    text-align:center;
                    font-size:18px;
                }
            }
        }
        @media (max-width: 1180px) {
            h2 {
                font-size:24px;
                margin-bottom:25px;
                span {
                    font-size:48px;
                }
            }
            ul {
                padding-top:35px;
                li {
                    img {
                        max-width:115px;
                    }
                    span {
                        font-size:40px;
                    }
                }
            }
        }
        @media (max-width: 1024px) {
            p.titre {
                font-size:16px;
            }
            a.link-go {
                font-size:16px;
            }
        }
        @media (max-width: 930px) {
            padding: 30px 0 0 0;
            h2 {
                font-size:20px;
                margin-bottom:15px;
                span {
                    font-size:40px;
                    margin-top:0;
                }
            }
            ul {
                li {
                    img {
                        max-width:80px;
                    }
                    span {
                        font-size:30px;
                        padding-top:5px;
                    }
                    p {
                        font-size:16px;
                        line-height:normal;
                    }
                }
            }
        }
    }
    .inner {
        display:flex;
        flex-wrap: wrap;
    }
    @media (max-width: 790px) {
         .inner {
            background:#f4f4f5 url(../images/agence-girl.jpg) no-repeat 0 0;
            background-size: auto 100%;
         } 
         div.img {
            img {
                visibility:hidden;
            }
         }
         div.silver {
            background:transparent;
         }  
    }
     @media (max-width: 710px) {
        div.silver {
            padding-left:30px;
        }
     }
    @media (max-width: 610px) {
        div.silver {
            ul {
                padding-top:15px;
                li {
                    img {
                        max-width:60px;
                    }
                    span {
                        font-size:24px;
                    }
                    p {
                        font-size:12px;
                    }
                }
            }
            h2 {
                font-size:20px;
                margin-bottom:1°px;
                span {
                    font-size:30px;
                }
            }
            p.titre {
                font-size:14px;
            }
            a.link-go {
                font-size:14px;
            }
        }
    }
    @media (max-width: 480px) {
        div.img {
            display:none;
        }
        div.inner {
            background:#f4f4f5 none;
        }
        div.silver {
            flex:0 0 100%;
            padding:15px;
        }
    }
}
.page-agency {
    .before-footer {
        margin-top:35px;
    }
}
.bann-contact {
    background: url(../images/banniere-contact.jpg) no-repeat top center;
    width: 100%;
    margin: 0 auto;
    margin-bottom: 60px;
    .inner-bann-contact {
        max-width: 1260px;
        margin: 0 auto;
        display: table;
        height: 427px;
        text-align: left;
        width: 100%;
        div.table {
            display: table-cell;
            vertical-align: middle;
        }
    }
    h2 {
        font-size:72px;
        color:$blue;
        font-weight:700;
        font-family:$aleg;
        margin-bottom:25px;
    }
    p.txt {
        font-size:18px;
        color:#777777;
        margin-bottom:25px;
    }
    a.phone {
        text-decoration: none;
        display: inline-block;
        border: 1px solid $blue;
        color: $blue;
        background: #FFF;
        padding: 13px 31px;
        font-size: 24px;
        border-radius: 28px;
        strong {
            text-transform:uppercase;
            font-weight:700;
        }
    }
    @media (max-width: 1260px) {
        background-size: cover;
        padding-left:15px;
        padding-right:15px;
    }
    @media (max-width: 992px) {
        margin-bottom:30px;
        h2 {
            font-size:60px;
        }
        a.phone {
            font-size:18px;
            padding:11px 25px;
        }
        .inner-bann-contact {
            height:380px;
        }
    }
    @media (max-width: 768px) {
        .inner-bann-contact {
            height:340px;
        }
        h2 {
            font-size:48px;
        }
        p.txt {
            font-size:16px;
        }
    }
     @media (max-width: 480px) {
        h2 {
            font-size:36px;
            margin-bottom:15px;
        }
        p.txt {
            font-size:14px;
        }
        a.phone {
            font-size:16px;
        }
     }
     @media (max-width: 380px) {
        .inner-bann-contact {
            height:280px;
        }
        h2 {
            font-size:30px;
            margin-bottom:10px;
        }
        p.txt {
            font-size:14px;
            br {
                display:none;
            }
        }
        a.phone {
            font-size:16px;
        }
     }
}
.banniere-service {
    background:url(../images/banniere-service.jpg) no-repeat top center;
    padding-top:75px;
    .row {
        height:450px;
    }
    h2 {
        color:$blue;
        font-size:48px;
        font-weight:100;
        span {
            font-size:72px;
            font-family:$aleg;
            font-weight:700;
        }
    }
    p.titre {
        color:#333333;
        font-size:18px;
        strong {
            font-size:24px;
            font-weight:700;
        } 
    }
    a.link-go {
        padding:17px 40px;
    }
    @media (max-width: 1340px) {
        .row {
            max-width:100%;
            padding-left:15px;
            padding-right:15px;
        }
    }
    @media (max-width: 1200px) {
        padding-top:0;
        background-size:cover;
        .row {
            height:400px;
            padding-top:35px;
            background-color: rgba(255, 255, 255, 0.6);
            background: rgba(255, 255, 255, 0.6);
        }
    }
    @media (max-width: 1024px) {
        h2 {
            font-size:34px;
            span {
                font-size:60px;
            }
        }
        p.titre {
            font-size:16px;
            strong {
                font-size:20px;
            }
        }
        a.link-go {
            padding:12px 30px;
            font-size:16px;
        }
    }
    @media (max-width: 640px) {
        p.titre {
            br {
                display:none;
            }
            br.only {
                display:inherit;
            }
        }
        h2 {
            font-size:26px;
            span {
                font-size:40px;
            }
        }
    }
}
.bloc-half {
    padding-bottom:145px;
    .row {
        max-width:1366px;
    }
    .img {
        padding-left:0;
        padding-right:0;
    }
    .txt {
        padding-left:105px;
        padding-right:60px;
        background:url(../images/service/pict-performant.png) no-repeat top right;
        h3 {
            color:#333333;
            font-size:36px;
            font-weight:100;
            margin-bottom:35px;
            span {
                font-size:80px;
                font-weight:700;
                font-family:$aleg;
                display:block;
                margin-top:-8px;
            }
        }
        p {
            font-size:18px;
            line-height:145%;
            margin-bottom:45px;
        }
        a.link-go {
            background:$blue;
            color:#FFF;
            padding:10px 48px;
        }
    }
    .bloc_2 {
        background:url(../images/service/pict-rentable.png) no-repeat top left;
    }
    .bloc_3 {
        background:url(../images/service/pict-efficace.png) no-repeat top right;
    }
    .bloc_4 {
        background:url(../images/service/pict-approprie.png) no-repeat top left;
    }
    .bloc_5 {
        background:url(../images/service/pict-impact.png) no-repeat top right;
    }
    .bloc_6 {
        background:url(../images/service/pict-percutant.png) no-repeat top left;
    }
    @media (max-width: 1340px) {
        .row {
            max-width:100%;
        }
    }
    @media (max-width: 1290px) {
        padding-bottom:75px;
        .txt {
            padding-left:40px;
            padding-right:15px;
            h3 {
                font-size:30px;
                span {
                    font-size:60px;
                }
            }
        }
        .txt_l {
            padding-left:105px;
            padding-right:15px;
        }
    }
    @media (max-width: 900px) {
        .txt {
            background-size:55px auto;
            h3 {
                font-size:25px;
                margin-bottom:25px;
                span {
                    font-size:50px;
                }
            }
            p {
                font-size:16px;
                margin-bottom:25px;
            }
            a.link-go {
                font-size:16px;
                padding:10px 35px;
            }
        }
        .txt_l {
            padding-left:70px;
            padding-right:15px;
        }
    }
    @media (max-width: 768px) {
        .img {
            text-align:center;
            padding-bottom:25px;
        }
        .txt,
        .txt_l {
            padding-left:15px;
            padding-right:15px;
            padding-bottom:25px;
            background-position:top right!important;
        }
    }
    @media (max-width: 380px) {
        .txt {
            h3 {
                font-size:20px;
                span {
                    font-size:40px;
                    margin-top:0;
                }
            }
        }
    }
}
.bann-service-detail {
    border-top:1px solid #e6e6e6;
    margin-bottom:35px;
    .row {
        max-width:1366px;
    }
    .txt {
        padding-top:65px;
        h3 {
            font-size:36px;
            color:#333333;
            margin-bottom:40px;
            span {
                font-size:80px;
                display:block;
                font-weight:700;
                font-family:$aleg;
            }
        }
        p {
            font-size:18px;
        }
    }
    .img {
        padding-right:0;
    }
    @media (max-width: 1366px) {
        .row {
            max-width:100%;
        }
    }
    @media (max-width: 1199px) {
            .txt {
                h3 {
                    font-size:30px;
                    span {
                        font-size:70px;
                    }
                }
            }
    }
    @media (max-width: 1024px) {
            .txt {
                h3 {
                    font-size:24px;
                    span {
                        font-size:50px;
                    }
                }
            }
    }
    @media (max-width: 480px) {
            .txt {
                h3 {
                    font-size:24px;
                    span {
                        font-size:38px;
                    }
                }
            }
    }
}
.info-service-detail {
    margin-bottom:35px;
    .row {
        max-width:1366px;
    }
    .txt {
        padding-left:45px;
        h3 {
            font-size:36px;
            color:#003333;
            margin-bottom:40px;
            font-weight:100;
            span {
                font-size:60px;
                display:block;
                font-weight:700;
                font-family:$aleg;
                color:#333333;
            }
        }
        p {
            font-size:18px;
        }
        ul {
            list-style:none;
            margin:0;
            padding:0;
            li {
                font-size:18px;
                padding-bottom:20px;
                strong {
                    font-weight:700;
                }
            }
        }
        a.link-go {
            background:$blu;
            color:#FFF;
            padding: 11px 36px;
            font-weight: 500;
            margin-top:30px;
        }
    }
    .img {
        padding-left:0;
    }
    @media (max-width: 1366px) {
        .row {
            max-width:100%;
        }
    }
    @media (max-width: 1280px) {
            .txt {
                h3 {
                    font-size:30px;
                    span {
                        font-size:50px;
                    }
                }
            }
    }
    @media (max-width: 1024px) {
            .txt {
                padding-left:15px;
                h3 {
                    font-size:24px;
                    margin-bottom:25px;
                    span {
                        font-size:40px;
                    }
                }
            }
    }
    @media (max-width: 768px) {
        .img {
            padding-left:15px;
            text-align:center;
            padding-bottom:25px;
        }
        .txt {
            a.link-go {
                margin-top:15px;
                font-size:16px;
            }
            ul {
                li {
                    padding-bottom:15px;
                    font-size:16px;
                }
            }
        }
    }
    @media (max-width: 480px) {
        .txt {
            h3 {
                font-size:20px;
                span {
                    font-size:30px;
                }
            }
        }
    }
}
.nouveau-service-detail {
    margin-bottom:0;
    .row {
        max-width:1366px;
    }
    .txt {
        padding-left:45px;
        h3 {
            font-size:36px;
            color:#003333;
            margin-bottom:40px;
            font-weight:100;
            span {
                font-size:60px;
                display:block;
                font-weight:700;
                font-family:$aleg;
                color:$blue;
            }
        }
        p {
            font-size:18px;
        }
        ul {
            list-style:none;
            margin:0;
            padding:0;
            li {
                font-size:18px;
                padding-bottom:20px;
                strong {
                    font-weight:700;
                }
            }
        }
        a.link-go {
            background:$blu;
            color:#FFF;
            padding: 11px 36px;
            font-weight: 500;
            margin-top:30px;
        }
    }
    @media (max-width: 1366px) {
        .row {
            max-width:100%;
        }
    }
    @media (max-width: 1280px) {
        .txt {
            h3 {
                font-size:30px;
                span {
                    font-size:50px;
                }
            }
        }
    }
    @media (max-width: 1160px) {
        margin-bottom:25px;
    }
    @media (max-width: 1024px) {
            .txt {
                padding-left:15px;
                h3 {
                    font-size:24px;
                    margin-bottom:25px;
                    span {
                        font-size:40px;
                    }
                }
            }
    }
    @media (max-width: 830px) {
        .txt {
            h3 {
                font-size:24px;
                span {
                    font-size:36px;
                }
            }
            a.link-go {
                margin-top:15px;
                font-size:16px;
            }
            ul {
                li {
                    padding-bottom:15px;
                    font-size:16px;
                }
            }
        }
    }
    @media (max-width: 768px) {
        .img {
            text-align:center;
        }
    }
    @media (max-width: 480px) {
        .txt {
            h3 {
                font-size:20px;
                span {
                    font-size:30px;
                }
            }
        }
    }
}
.contact-service {
    padding-top:0;
}
.page-vitrine {
    .bann-service-detail {
        .txt {
            h3 {
                display:inline-block;
                background:url(../images/service/pict-vitrine.png) no-repeat center right;
                padding-right:120px;
            }
            @media (max-width: 1199px) {
                h3 {
                    background-size:auto 50px;
                    padding-right:100px;
                }
            }
        }
    }
}
.banniere-propos {
    background:url(../images/banniere-contact.jpg) no-repeat top center;
    .row {
        max-width:1366px;
        color:#FFF;
        min-height:360px;
    }
    .BreadCrumbs {
        margin-bottom:25px;
        margin-top:5px;
        color:$blue;
        max-width:100%;
        a {
            color:$blue;
        }
        span {
            background-position:0 6px;
        }
    }
    h3 {
        text-transform:uppercase;
        font-weight:700;
        font-size:48px;
        margin-bottom:20px;
        font-size: 72px;
        font-family: $aleg;
        font-weight: 700;
        color:$blue;
    }
    p {
        font-size:18px;
    }
    @media (max-width: 1024px) {
        background-size:cover;
        .row {
            min-height:100px;
            padding-bottom:20px;
        }
        h3 {
            font-size:40px;
        }
        p {
            font-size:16px;
        }
    }
    @media (max-width: 640px) {
        h3 {
            font-size:30px;
        }
        p {
            font-size:16px;
            br {
                display:none;
            }
        }
    }
    .central {
        display:table;
        height:100%;
        vertical-align:middle;
        max-width:1260px;
        margin:0 auto;
        padding-top:20px;
    }
    .central-inner {
        display:table-cell;
        vertical-align:middle;
        text-align:left;
    }
}
#header {
    background:#FFF;
}
.bloc-service {
    background:url(../images/bg-service.jpg) no-repeat top center;
    .row {
        min-height:1281px;
        max-width:100%;
    }
    h2 {
        color:#00548a;
        font-family:$aleg;
        font-size:54px;
        font-weight:700;
        margin-top:80px;
        margin-bottom:25px;
        span {
            font-weight:100;
            font-size:23px;
        }
    }
    p.titre {
        font-size:18px;
        color:#333333;
    }
    .triangle {
        float:right;
        width:63%;
        color:#FFF;
        .bloc {
            width:40%;
            float:left;
            h3 {
                font-size:40px;
                font-weight:700;
                font-weight:900;
                line-height:100%;
                margin-bottom:25px;
                font-family:$aleg;
            }
            p {
                font-size:24px;
                font-weight:100;
                margin-bottom:0;
            }
        }
        .first-bloc.bloc {
            width:60%;
            padding-top:125px;
        }
    }
    p.cta {
        clear:both;
        text-align:center;
        padding:165px 0 0 0;
        margin-bottom:0;
        a.ctn {
            background: $blue;
            color: #FFF;
            margin-left: 15px;
        }
        a.ctn:hover {
            background:#FFF;
            color:$blue;
        }
    }
    @media (max-width: 1100px) {
        background-size: cover;
        h2 {
            margin-top:50px;
            font-size:48px;
        }
        .triangle {
            .bloc {
                h3 {
                    font-size:34px;
                }
                p {
                    line-height:120%;
                    font-size:20px;
                }
            }
        }
        .row {
            position:relative;
            min-height:1200px;
            p.cta {
                position:absolute;
                bottom:75px;
                left:0;
                width:100%;
            }
        }
    }
    @media (max-width: 992px) {
        .row {
            min-height:1000px;
        }
        .triangle {
            width:80%;
            .bloc {
                h3 {
                    font-size:30px;
                    margin-bottom:5px;
                }
            }
        }
        h2 {
            margin-top:25px;
            font-size:40px;
            margin-bottom:0;
        }
        p.titre {
            font-size:16px;
        }
    }
    @media (max-width: 640px) {
        background:none;
        .row {
            min-height:299px;
            p.cta {
                position:static;
                padding-top:25px;
                a.link-go {
                    padding:10px 25px;
                }
            }
        }
        .triangle {
            width:100%;
            background:$blue;
            padding:15px;
            .first-bloc.bloc {
                width:100%;
            }
            .bloc {
                width:100%;
                padding:0;
                h3 {
                    margin-top:0;
                }
                div {
                    padding-bottom:15px;
                }
            }
            .first-bloc.bloc {
                padding:0;
            }
        }
    }
    @media (max-width: 480px) {
        p.cta {
            a {
                display:block;
                width:100%;
                margin-bottom:15px;
            }
            a.ctn {
                margin:0;
            }
        }
    }
}
.banniere-titre {
    width:100%;
    background:url(../images/hachure.jpg) no-repeat top right;
    margin:45px 0 0 0;
    .infos {
        height:400px;
        display:table;
        width:100%;
        .infos-inner {
            display:table-cell;
            vertical-align:middle;
            .clearfix {
                background:$blue;
                max-width:600px;
                width:100%;
                display: flex;
                align-items: center;
            }
            .logo {
                img {
                    display:block;
                }
            }
            .black {
                width:calc( 100% - 200px);
                color:#FFF;
                p {
                    margin:0;
                    font-size:18px;
                    font-weight:400;
                    font-family: 'Lato', sans-serif;
                    padding:0 0 0 45px;
                    strong {
                        font-weight:normal;
                        color:#FFF;
                    }
                    a {
                        color:#FFF;
                    }
                    span {
                        display:block;
                        font-size:16px;
                        margin-top:15px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 768px) {
        background-size:cover;
        margin:0;
        .infos {
            height:280px;
            .infos-inner {
                .clearfix {
                    max-width:100%;
                    .logo {
                        width:30%;
                    }
                }
                .black {
                    width:70%;
                    p {
                        padding-left:15px;
                        padding-right:15px;
                        font-size:18px;
                    }
                }
            }
        }
    }
    @media screen and (max-width: 480px) {
        .infos {
            .infos-inner {
                .black {
                    p {
                        padding-left:15px;
                        padding-right:15px;
                        font-size:16px;
                        line-height:normal;
                    }
                }
                .clearfix .logo {
                    width:45%;
                }
            }
        }
    }
    @media screen and (max-width: 380px) {
        .infos {
            height:220px;
            .infos-inner {
                .black {
                    p {
                        font-size:12px;
                    }
                }
                .clearfix .logo {
                    width:40%;
                }
            }
        }
    }
}
.art-list {
    background:#f1f1f1;
    margin-bottom:45px;
    .row {
        max-width:100%;
    }
    div.left {
        padding-left:50px;
        padding-right:45px;
        padding-top:45px;
        h2 {
            display:block;
            color: $blue;
            font-family: $aleg;
            font-size: 60px;
            font-weight: 700;
            margin-top:0;
            margin-bottom:35px;
            display:none;
        }
        p {
            font-size:16px;
        }
    }
    div.right {
        padding-right:0;
        padding-left:80px;
        padding-right:50px;
        padding-top:45px;
        padding-bottom:25px;
        background:$blue;
        color:#FFF;
        h3 {
            font-size:26px;
            font-weight:700;
            margin-bottom:35px;
            margin-top:0;
        }
        ul {
            list-style:none;
            padding:0;
            margin:0;
            div.img {
                background:#FFF;
                width:124px;
                height:124px;
                box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.2);
                float:left;
                border-radius:50%;
                overflow:hidden;
            }
            div.txt {
                float:right;
                width:calc( 100% - 150px);
            }
            li {
                @include clearfix();
                padding-bottom:25px;
            }
            p.titre {
                font-size:14px;
                font-weight:700;
                margin-bottom:10px;
            }
            p.txt {
                font-size:14px;
                margin-bottom:0;
                line-height:normal;
                a {
                    color:#FFF;
                    text-decoration:none;
                }
            }
        }
    }
    @media (max-width: 1024px) {
        padding-top:0;
        div.left {
            padding-left:45px;
        }
        div.right {
            padding-left:45px;
        }
    }
    @media (max-width: 768px) {
        padding-top:0;
        margin-bottom:0;
        .row {
            margin:0;
        }
        div.left {
            padding-left:15px;
            padding-right:15px;
            padding-top:0;
            h2 {
                margin-bottom:25px;
            }
            .field-type-image img {
                margin:0;
                display:block;
            }
        }
        div.right {
            padding-left:15px;
            padding-right:15px;
            padding-top:15px;
            ul {
                div.img {
                    width:90px;
                    height:90px;
                }
                div.txt {
                    width: calc( 100% - 100px);
                }
            }
        }
    }
}
.node-type-temoignage {
    .bann-intern h2 {
        color:$blue;
        text-shadow:none;
    }
    .art-list {
        div.left {
            h2 {
            font-size: 26px;
            font-weight: 700;
            margin-bottom: 35px;
            margin-top: 0;
            display:block;
            }
            .meta.submitted {
                display:none;
            }
        }
    }
    @media (max-width: 768px) {
        .art-list div.left {
            padding-top:15px;
            padding-bottom:15px;
            h2 {
                margin-bottom:15px;
            }
        }
    }
}
.bann-intern {
    background:url(../images/banniere-interne.jpg) no-repeat top center;
    max-width:1366px;
    margin:0 auto;
    height:400px;
    .inner-bann-intern {
        display:table;
        width:100%;
        height:100%;
        padding:0 80px;
        background: rgba(0, 84, 139, 0.3);
        .table {
            display:table-cell;
            vertical-align:middle;
            color: #FFF;
        }
    }
    h2 {
        font-size:48px;
        font-weight:700;
        font-family: 'Montserrat', sans-serif;
        margin:0;
        position:relative;
        padding-bottom:10px;
        margin-bottom:25px;
        text-shadow: 2px 2px 2px #00548a;
    }
    h2:after {
        content:"";
        display:block;
        position:absolute;
        width:59px;
        height:7px;
        background:$blue;
        bottom:0;
        left:0;
    }
    .BreadCrumbs {
        border:none;
        color:#FFF;
        margin-top:10px;
        a {
            color:#FFF;
        }
        em {
            color:#FFF;
        }
        span {
            background-image:url(../images/home.png);
            padding:9px 0px 4px 32px;
        }
    }
    @media (max-width: 768px) {
        background-size: cover!important;
        height:300px;
        h2 {
            font-size:38px;
            line-height:100%;
            padding-bottom:20px;
        }
        .inner-bann-intern {
            padding:0 25px;
        }
    }
}
.footer-silver {
    background:#e6e6e6;
    p {
        margin:0;
        text-align:center;
        padding:15px 0;
        font-size:14px;  
        a {
            color:#333333;
            text-decoration:none;
        }
        a:hover {
            color:$blue;
        }
    }
    @media (max-width: 1024px) {
        padding-left:15px;
        padding-right:15px;
    }
}
.contact-form .resizable-textarea .grippie {
    display:none!important;
}
.lst-service {
    .col-lg-6 {
        padding-right:0;
        padding-left:0;
        h2 {
            font-size:34px;
            font-weight:700;
            color:#FFF;
            margin:0;
            font-family: 'Montserrat', sans-serif;
            margin-bottom:32px;
            line-height:100%;
        }
        p {
            font-size:14px;
            line-height:125%;
            margin-bottom:30px;
        }
        a {
            font-size:18px;
            text-transform:uppercase;
            font-weight:600;
            border:1px solid #FFF;
            padding:16px 25px;
            display:inline-block;
            color:#FFF;
            text-decoration:none;
            background:transparent;
        }
        a:hover {
            background:#FFF;
            color:$blue;
        }
        .inner-txt {
            
        }
        .outer-txt {
            
        }
    }
    .row {
        background:$blue;
        color:#FFF;
        max-width:1366px;
        div.txt {
            padding:45px 45px;
        }
        .img {
            img {
                width:100%;
                display:block;
            }
        }
    }
    div.item:nth-child(even) {
        div.img {
            text-align:left;
        }
        .row {
            background:#FFF;
            color:$blue;
            .col-lg-6 {
                h2 {
                    color:$blue;
                }
                a {
                    border-color:$blue;
                    color:$blue;
                }
                a:hover {
                    background:$blue;
                    color:#FFF;
                }
            }
        }
    }
    div.img {
        text-align:right;
    }
    @media screen and (max-width: 480px) {
        .row {
            div.txt {
                padding:30px;
            }
        }
        .col-md-6 {
            h3 {
                font-size:30px;
                margin-bottom:25px;
            }
            h2 {
                font-size:24px;
            }
        }
    }
}
.state {
    .row {
        background:#b1a684;
    }
}
.banniere-infos {
    margin:42px -15px 0 -15px;
    position: relative;
    z-index: 2;
    .txt-infos {
        background:$blue;
        color:#FFF;
        font-size:16px;
        p {
            margin:0;
            font-size:30px;
            font-weight:700;
            position:relative;
            span.quote {
                top:-35px;
                left:-33px;
            }
        }
          &:before,
          &:after {
            background: inherit;
            content: '';
            display: block;
            height: 50%;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
            -webkit-backface-visibility: hidden; // for Chrome Windows
          }

          &:before {
            top: 0;
            transform: skewY(5.5deg);
            transform-origin: 100% 0;
          }
          
          &:after {
            bottom: 0;
            transform: skewY(-5.5deg);
            transform-origin: 100%;
          }
        div.inner {
            width:100%;
            height:100%;
            display:table;
            .outer {
                display:table-cell;
                vertical-align:middle;
                padding-left:25px;
            }
        }
    }
    .txt-details-infos {
        padding-left:0;
        position:relative;
        padding-right:0;
        img {
            display:block;
            max-height:370px;
            width:100%;
        }
        p {
            font-size:24px;
            font-weight:100;
            line-height:140%;
            margin:0;
            padding:35px 0 0 15px;
            strong {
                font-weight:700;
            }
        }
        .BreadCrumbs {
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            background-color: rgba(0, 0, 0, 0.5); 
        }
        .BreadCrumbs {
            position:absolute;
            bottom:0;
            left:0;
            width:100%;
            background-color: rgba(0, 0, 0, 0.5); 
            color:#FFF;
            padding-left:25px;
            a {
                color:#FFF;
            }
            em {
                color:#FFF;
            }
            span {
                background-image:url(../images/home.png);
                padding:9px 0px 4px 32px;
            }
        }
    }
    @media (max-width: 1280px) {
        .txt-infos {
            p {
                font-size:24px;
            }
        }
        .txt-details-infos {
            img {
                max-height:auto;
            }
        }
    }
    @media (max-width: 770px) {
        margin:0;
       .txt-infos:before {
            display:none;
       }
       .txt-infos {
            div.inner {
                .outer {
                    padding-left:0;
                }
            }
            p {
                text-align:center;
                padding:25px 0;
            }
       }
    }
    @media (max-width: 480px) {
        
    }
}
.page-taxonomy-term- {
    .bann-intern .inner-bann-intern {
        background: rgba(0, 84, 139, 0.3);
    }
}
.silver-infos {
    background:#e5e7e9;
    padding:0;
    color:#FFF;
    font-size:16px;
    position:relative;
    margin:50px -15px 0 -15px;
    color:#000;
    z-index:1;
     &:before,
          &:after {
            background: inherit;
            content: '';
            display: block;
            height: 50%;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
            -webkit-backface-visibility: hidden; // for Chrome Windows
          }

          &:before {
            top: 0;
            transform: skewY(2deg);
            transform-origin: 100% 0;
          }
          
          &:after {
            bottom: 0;
            transform: skewY(-2deg);
            transform-origin: 59%;
          }
    h2 {
        color:#001628;
        border-color:#001628;
        border-bottom:1px solid #001628;
        margin:0;
        margin-bottom:25px; 
        font-size:22px;
        padding-bottom:6px;
    }
    ul {
        margin:0;
        list-style-position:inside;
        padding:0;
        margin-left:15px;
        margin-bottom:25px;
    }
    ul li {
        font-size: 16px;
        color: #333333;
        span {
            color: #333333;
        }
    }
    .prog-infos {
        padding:45px;
        p {
            font-size:16px;
            margin-bottom:25px;
        }
    }
    @media (max-width: 770px) {
        margin:0;
        .prog-infos {
            padding:45px 25px;
        }
    }
    @media (max-width: 480px) {
        margin:0;
        .prog-infos {
            padding:25px 15px;
            h2 {
            font-size:18px;
            }
            p {
                font-size:14px;
            }
            ul {
                margin-left:0;
                li {
                    font-size:14px;
                }
            }
        }
    }
}
@media (max-width: 770px) {
    .silver-infos:after {
        display:none;
    }
}
    .contact-infos {
        background:#001628;
        padding:0 30px 60px 30px;
        &:before,
          &:after {
            background: inherit;
            content: '';
            display: block;
            height: 50%;
            left: 0;
            position: absolute;
            right: 0;
            z-index: -1;
            -webkit-backface-visibility: hidden; // for Chrome Windows
          }

          &:before {
            top: 0;
            transform: skewY(-2.5deg);
            transform-origin: 0% 0;
          }
          
          &:after {
            bottom: 0;
            transform: skewY(2.5deg);
            transform-origin: 0%;
            z-index:1;
            height:28px;
          }
        p.titre {
            text-transform:uppercase;
            font-size:36px;
            color:red;
            letter-spacing:-2px;
            padding-top:10px;
        }
        @media (max-width: 480px) {
            p.titre {
                font-size:28px;
                letter-spacing:normal;
            }
        }
    }

.setting-form {
    background:$blue;
    padding:60px 0 20px 0;
    text-align:right;
    p {
        margin:0;
        color:#FFF;
        font-size:22px;
        font-weight:300;
        line-height:125%;
        padding:0 25px;
        strong {
            display:inline-block;
            background:url(../images/picto-duration-3.png) no-repeat center right;
            padding:0 25px 0 0;
        }
        a {
            color:#FFF;
        }
    }
    p:last-child {
        padding-top:5px;
    }
    p.nb strong {
        background:url(../images/picto-duration-2.png) no-repeat center right;
    }
    p.lieu strong {
        background:url(../images/picto-duration-1.png) no-repeat center right;
    }
    @media (max-width: 770px) {
        padding:25px 0 20px 0;
        p {
            font-size:18px;
        }
    }
    @media (max-width: 480px) {
        padding:25px 0 20px 0;
        p {
            font-size:16px;
        }
    }
}
.node-type-service {
    .site-footer .layout-container {
        position:relative;
        max-width: 1396px;
    }
    .site-footer .layout-container:before {
        background:$blue;
        content: '';
        display: block;
        height: 35%;
        left: 0;
        position: absolute;
        right: 0;
        z-index:1;
        -webkit-backface-visibility: hidden;
        top: 0;
        transform: skewY(2deg);
        transform-origin: 100% 0;
        top:-45px;
    }
    .before-footer {
        padding-top:75px;
    }
    @media (max-width: 1400px) {
        #page {
            overflow:hidden;
        }
    }
    @media (max-width: 770px) {
        .site-footer .layout-container:before {
            display:none;
        }
        .before-footer {
            padding-top:15px;
        }
    }
}
.page-user {
    #block-system-main {
        max-width: 768px;
        margin: 0 auto;
        padding: 45px 0;
        min-height: 640px;
        input#edit-submit {
            text-decoration: none;
            display: inline-block;
            border: 1px solid $blue;
            color: $blue;
            background: #FFF;
            padding: 17px 55px;
            font-weight: 700;
            text-transform: uppercase;
            font-size: 18px;
            border-radius: 28px;
            margin: 0;
        }
        input.form-text {
            width: 100%;
            border: 1px solid $blue;
            background: transparent;
            color: $blue;
            font-size: 16px;
            padding: 17px 25px;
            border-radius: 28px;
        }
        .form-item {
           margin-bottom:30px; 
        }
        .form-item .description {
            display:none;
        }
        .form-item label {
            font-size:18px;
            color: $blue;
        }
    }
}
.bloc-add-forum {
    padding-bottom:25px;
    h3 {
        color: $blue;
        font-family: $aleg;
        font-size: 60px;
        font-weight: 700;
        margin: 0;
        padding-top: 25px;
    }
    .col-md-12 > div > a {
        text-decoration: none;
        display: inline-block;
        border: 1px solid $blue;
        color: $blue;
        background: #FFF;
        padding: 6px 20px;
        font-weight: 700;
        text-transform: uppercase;
        font-size: 14px;
        border-radius: 28px;
    }
    @media (max-width: 1024px) {
        h3 {
            font-size: 48px;
            line-height: normal;
            padding-bottom: 25px;
        }
    }
    @media (max-width: 770px) {
        h3 {
            font-size: 40px;
            padding-bottom: 15px;
            padding-top:15px;
        }
    }
    @media (max-width: 480px) {
        padding-bottom:15px;
        h3 {
            font-size: 34px;
            padding-bottom: 15px;
            padding-top:15px;
        }
    }
}
.bloc-list-forum {
    .col-md-12 {
        > h3 {
            display:none;
        }
    }
}
.page-node-add-forum {
    #forum-node-form {
        padding-top:25px;
    }
}
.node-type-forum {
    #block-system-main {
        .node-forum {
            > .meta {
                display:none;
            }
            > div.content {
                padding-top:25px;
                .field-type-text-with-summary {
                    p {
                        color: $blue;
                        font-family: $aleg;
                        font-size: 24px;
                        font-weight: 700;
                        line-height:100%;
                    }
                }
            }
        }
        .field-name-taxonomy-forums {
            display:none;
        }
        .comment .comment-arrow {
            display:none;
        }
        h2.comment-form {
            color: $blue;
            font-family: $aleg;
            font-size: 18px;
            font-weight: 700;
            margin:35px 0 20px 0;
        }
        .comment .comment-text {
            padding: 10px 15px;
            border: 1px solid $blue;
            h3 {
                font-weight:700;
                margin:0;
                color:$blue;
            }
            p {
                margin:0;
            }
            .content {
                margin:0;
            }
            a {
                color:$blue;
            }
        }
        .comment .attribution {
            min-width:150px;
            .username {
                font-weight:bold;
                color:$blue;
            }
        }
        .comment .submitted .comment-permalink {
            display:none;
        }
    }
    .comment-wrapper {
        #google_recaptcha_comment_node_forum_form {
            margin-bottom:20px;
        }
    }
}
.page-sitemap {
    .site-map-front-page {
        display:none;
    }
    .site-map-menus {
        @include clearfix();
        max-width:840px;
        margin:0 auto;
        .site-map-box-menu {
            float:left;
            width:50%;
            margin:0;
            h2 {
                color: $blue;
                font-family:$aleg;
                font-size: 24px;
                font-weight: 700;
            }
        }
        .site-map-box-menu-menu-footer {
            float:right;
        }
    }
    .site-map-taxonomys {
        display:none;
    }
    ul.site-map-menu {
        list-style:none;
        padding:0;
        margin:0;
        li {
            position:relative;
            padding:0 0 0 15px;
            a {
                color:#333;
                font-size:24px;
            }
        }
        li:before {
            content:"";
            display:block;
            width:7px;
            height:7px;
            background:$blue;
            position:absolute;
            top: 14px;
            left: 0;
            border-radius: 50%;
        }
        li.leaf {
            list-style:none;
        }
        li.expanded {
            list-style:none;
            > a {
                font-weight:700;
            }
        }
    }
    @media (max-width: 770px) {
        ul.site-map-menu {
            li {
                a {
                    font-size:18px;
                }
            }
            li:before {
                top:11px;
            }
            ul {
                padding-left:0;
            }
        }
        .site-map-menus {
            .site-map-box-menu {
                h2 {
                    font-size:20px;
                }
            }
        }
    }
    @media (max-width: 480px) {
        .site-map-menus {
            .site-map-box-menu {
                float:none;
                width:100%;
            }
        }
    }
}
.bloc-quest-xp {
    padding-top:45px;
    .col-md-12 {
    }
    ul {
        list-style:none;
        padding:0;
        margin:0;
    }
    h3 {
        font-size:38px;
        color:$blue;
        font-weight:700;
        margin:30px 0;
        text-transform:uppercase;
        position:relative;
        margin-bottom:45px;
        span {
            background:#FFF;
            display:inline-block;
            z-index:1;
            position:relative;
            padding:0 13px 0 0;
        }
    }
    h3:after {
        width:100%;
        height:1px;
        background:#E06802;
        position:absolute;
        top:50%;
        left:0;
        content:"";
        display:block;
    }
    ul.blue {
        padding-bottom:5px;
        li {
            padding:0 0 15px 0;
            p {
                display:block;
                text-decoration:none;
                background:$blue;
                box-shadow: 0 2px 4px 0 rgba(0,0,0,0.50);
                color:#FFF;
                font-size:27px;
                padding:11px 75px 10px 16px;
                position:relative;
                border-radius:4px;
                line-height:normal;
            }
            strong {
                display:block;
                background: #E06802;
                position:absolute;
                top:0;
                right:0;
                width:57px;
                height:100%;
                border-radius:4px;
                width:220px;
                font-size:20px;
                font-weight:normal;
                text-align:center;
                padding-top:14px;
            }
        }
    }
    ul.silver {
        padding-bottom:5px;
        @include clearfix();
        li {
            padding:0 0 15px 0;
            width:49%;
            float:left;
            a {
                display:block;
                text-decoration:none;
                background: rgba(155, 155, 155, 0.08);
                color:#075679;
                font-size:27px;
                padding:17px 70px 17px 26px;
                position:relative;
                border-radius:4px;
                line-height:normal;
            }
            a:after {
                content:"";
                display:block;
                background:url(../images/arrow-sp-orange.svg) no-repeat center;
                position:absolute;
                top:0;
                right:0;
                width:73px;
                height:100%;
                border-radius:4px;
            }
        }
        li:nth-child(even) {
            float:right;
        }
    }
    @media (max-width: 1100px) {
        ul.blue {
            li {
                p {
                    span {
                        display:block;
                        width:calc(100% - 230px);
                    }
                }
            }
        }
    }
    @media (max-width: 1280px) {
        .col-md-12 {
            padding-left:15px;
        }
    }
    @media (max-width: 1024px) {
        ul.blue {
            li {
                p {
                    font-size:20px;
                    padding-right:0;
                    span {
                        width:calc(100% - 175px);
                    }
                    strong {
                        font-size:18px;
                        width:175px;
                        padding-top:10px;
                    }
                }
            }
        }
    }
    @media (max-width: 768px) {
        padding-bottom:0;
        padding-top:0;
        h3:after {
            display:none;
        }
        h3 {
            font-weight:400;
            text-align:center;
            font-size:34px;
            margin-bottom:30px;
            line-height:normal;
            span {
                padding:0;
            }
        }
        ul.blue {
            padding-bottom:0;
            li {
                a {
                    font-size:20px;
                }
            }
            li:last-child {
                padding-bottom:0;
            }
        }
        ul.silver {
            margin-top:35px;
            li {
                width:100%;
                a {
                    font-size:20px;
                }
            }
        }
    }
    @media (max-width: 480px) {
        ul.blue {
            li {
                p {
                    padding:10px 15px 10px 15px;
                    span {
                        display:block;
                        width:100%;
                        padding-bottom:10px;
                    }
                    strong {
                        position:static;
                        width:100%;
                        padding:10px 0;
                    }
                }
            }
        }
    }
}
@media (min-width: 992px) {
  #block-bartik-main-menu li.menu-item .submenu {
    display: none;
  }
  #block-bartik-main-menu li.menu-item:hover .submenu {
    display: inline-block;
  }
  #block-bartik-main-menu ul.menu > li > a {
    border-top: 5px solid #fff;
  }
  #block-bartik-main-menu ul.menu > li > a:hover {
    border-top: 5px solid rgba(185, 0, 0, 1);
  }
  #block-bartik-main-menu li.menu-item .submenu {
    width: 280px;
    top: 37px;
    left: 10px;
  }
  #block-bartik-main-menu li.menu-item .submenu li {
    border-bottom: 1px solid #ccc;
    background: rgba(0 ,84, 138, 0.9);
  }
  #block-bartik-main-menu li.menu-item .submenu li:hover {
    background: rgba(0 ,84, 138, 1);
  }
  #block-bartik-main-menu li.menu-item .submenu li a,
  #block-bartik-main-menu li.menu-item .submenu li a.active {
    padding: 8px 20px;
    color: #fff;
  }
}

canvas {
    display: block;
    vertical-align: bottom;
}


/* ---- particles.js container ---- */

#particles-js {
    position: absolute;
    width: 100%;
    height: 110%;
    background-image: url("");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 50% 50%;
}


/* ---- stats.js ---- */

.count-particles {
    background: #000022;
    position: absolute;
    top: 48px;
    left: 0;
    width: 80px;
    color: #13E8E9;
    font-size: .8em;
    text-align: left;
    text-indent: 4px;
    line-height: 14px;
    padding-bottom: 2px;
    font-family: Helvetica, Arial, sans-serif;
    font-weight: bold;
}

.js-count-particles {
    font-size: 1.1em;
}

#stats,
.count-particles {
    -webkit-user-select: none;
    margin-top: 5px;
    margin-left: 5px;
}

#stats {
    border-radius: 3px 3px 0 0;
    overflow: hidden;
}

.count-particles {
    border-radius: 0 0 3px 3px;
}
.bloc-down {
   border-radius: 16px;
   background:#f5f5f5;
   width:225px;
   padding-top:15px;
   padding-right:15px;
   padding-bottom:15px;
   position: absolute;
    right: 0;
    bottom:0;
   .blue {
     margin-left:-25px;
     border-radius: 8px;
    -webkit-box-shadow: 0 0 30px rgba(16,14,23,.25);
    box-shadow: 0 0 30px rgba(16,14,23,.25);
    position:relative;
    img {
        display:block;
    }
    .inner-blue {
        width:100%;
        padding:15px 10px;
        background:#00548a;
        min-height:170px;
        border-radius: 8px 8px 0 0;
    }
    h3 {
        margin:0;
        font-size:20px;
        font-weight:700;
        margin-bottom:20px;
    }
    a {
        float:left;
        width:50%;
        position:relative;
    }
    a.close {
        width:auto;
        color:#FFF;
        float: none;
        position: absolute;
        top: -21px;
        right: -19px;
        width: 20px;
        height: 20px;
        text-align: center;
        display:none;
        span {
            display:none;
        }
        &:before {
            display:block;
            content:"X";
            font-size:12px;
            color:#000;
            border:1px solid #000;
            border-radius:50%;
            font-weight:bold;
            background:#FFF;
        }
    }
    .mac {
        display:block;
        img {
            width:85px;
        }
    }
    .win {
        img {
            width:85px;
        }
    }
    .mac,
    .win {
        z-index:99;
    }
   }
   p {
    margin:0;
    color:#a1a1af;
    font-size:14px;
    padding:10px 0 10px 10px;
    line-height:125%;
    background:#FFF;
    @include clearfix();
    span {
        display:block;
        -webkit-filter: grayscale(100%);
        filter: grayscale(100%);
        border-radius: 50%;
        width: 40px;
        height: 40px;
        overflow:hidden;
        float:left;
    }
    strong {
        float:right;
        width: calc( 100% - 47px );
    }
   }
   @media (max-width: 1280px) {
       z-index:999;
       right:25px;
       width:230px;
   }
   @media (max-width: 768px) {
        position:fixed;
        bottom:0;
        right:25px;
        .blue {
            a.close {
                display:block;
            }
        }
   }
   @media (max-width: 480px) {
        right:15px;
        padding:10px 10px 10px 0;
        width:140px;
        .blue {
            h3 {
                font-size:12px;
                margin-bottom:10px;
            }
            .mac,
            .win {
                text-align:center;
                img {
                    width:35px;
                    display:inline-block;
                }
            }
            .inner-blue {
                min-height:90px;
            }
        }
        p {
            font-size:11px;
            span {
                width:25px;
                height:25px;
            }
            strong {
                width:calc( 100% - 30px);
            }
        }
   }
}
    